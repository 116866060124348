<template>
    <Dialog v-model:visible="visible" modal :header="modalHeader" :style="{ width: '50vw' }"
        @after-hide="selectedOption = null" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <!-- show radio buttons here for options -->
        <div v-for="option in getComputedOptions" :key="option.value">
            <div class="my-2">
                <input type="radio" v-model="selectedOption" :value="option.value" :label="option.label"
                    :id="option.label" />
                <label :for="option.label">{{ option.label }}</label>
            </div>
        </div>
        <!-- show submit button here -->
        <Button label="Submit" icon="pi pi-check" class="mt-2" @click="handleSubmit" />
    </Dialog>
</template>
<script>
export default {
    props: {
        modalHeader: {
            type: String,
            required: true,
        },
        onDelete: {
            type: Function,
            required: false,
        },
        onEdit: {
            type: Function,
            required: false,
        },
        actionMode: {
            type: String,
            required: true,
        },
        isRecurring: {
            type: Boolean,
            required: true,
        },
        recurranceRule: {
            type: Boolean,
            required: true,
        }
    },
    methods: {
        handleSubmit() {
            if (!this.selectedOption) {
                return;
            }
            if (this.actionMode === 'delete') {
                this.onDelete(this.selectedOption);
            } else {
                this.onEdit(this.selectedOption);
            }
        }
    },
    computed: {
        getComputedOptions() {
            if (this.isRecurring) {
                if (this.actionMode === 'edit' && this.recurranceRule) {
                    return this.options.filter(option => option.value !== 1)
                }
                return this.options;
            }
            return this.options.filter(option => option.value !== 2 && option.value !== 3)
        }
    },
    data() {
        return {
            selectedOption: null,
            options: [
                { label: "This Event", value: 1 },
                { label: "This Event and Future Events", value: 2 },
                { label: "All Events", value: 3 },
            ]
        }
    },
}
</script>