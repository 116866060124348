<template>
  <div class="col-12" v-if="dynamicForm">
    <CustomLoader :loading="isBusy" />

    <form id="formSubmit" @submit.prevent="submitAction" v-show="!isBusy">
      <div class="grid">
        <div class="col-12">
          <label class="block mb-2 font-bold">Date & Time</label>
          <input
            type="datetime-local"
            name="FormField_DateTime"
            class="p-inputtext p-component w-full"
            v-model="dateTime"
            required
          />
        </div>
        <template v-for="(field, index) in getDynamicFormFields" :key="index">
          <div
            class="col-12"
            v-if="field.fieldType && field.fieldType != 'checkbox'"
          >
            <label class="block mb-2 font-bold">{{ field.label }}</label>
            <input
              class="p-inputtext p-component w-full"
              :type="field.fieldType"
              :name="'FormField_' + field.displayOrder"
              :id="'FormField_' + field.displayOrder"
              :required="field.isRequired"
              :value="field.formValue"
            />
          </div>

          <div class="col-12" v-if="field.isMultipleChoice">
            <label class="block mb-2 font-bold">{{ field.label }}</label>
            <select
              class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
              :name="'FormField_' + field.displayOrder"
              :id="'FormField_' + field.displayOrder"
              :value="field.formValue"
              :required="field.isRequired"
            >
              <option
                v-for="(text, index) in field.multipleQuestions"
                :key="index"
                :value="text"
              >
                {{ text }}
              </option>
            </select>
          </div>
          <div class="col-12" v-if="field.fieldType == 'checkbox'">
            <label class="block mb-2 font-bold">{{ field.label }}</label>
            <input
              :type="field.fieldType"
              :checked="field.formValue === 'true'"
              @input="(e) => onCheckChange(e)"
            />
            <input
              :name="'FormField_' + field.displayOrder"
              :value="field.formValue === 'true' ? 'on' : 'off'"
              hidden
              :id="'FormField_' + field.displayOrder"
            />
          </div>
        </template>
        <div class="col-12" v-if="showCancelReason">
          <label class="block mb-2 font-bold">Cancel Reason?</label>
          <textarea
            name="FormField_Notes"
            class="p-inputtext p-component w-full"
            rows="5"
          ></textarea>
        </div>
        <div class="col-12 text-right buttons-grid">
          <!-- <input ref="formSubmit" label="Add" icon="pi pi-check" type="submit" /> -->
          <Button
            size="small"
            label="Re-send Email"
            icon="pi pi-envelope"
            v-if="eventDetails.OverrideType === 0"
            @click="handleEmailResend"
            severity="warning"
          />
          <Button
            size="small"
            label="Reschedule"
            icon="pi pi-clock"
            v-if="eventDetails.OverrideType === 0"
            @click="handleReschedule"
            severity="warning"
          />
          <Button
            size="small"
            label="Cancel"
            icon="pi pi-times"
            @click="eventCancel"
            v-if="eventDetails.OverrideType === 0"
            severity="danger"
          />
          <Button size="small" label="Save" type="submit" icon="pi pi-check" />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import CustomLoader from "@/components/CustomLoader.vue";
export default {
  props: {
    dynamicForm: Object,
    submitAction: Function,
    eventDetails: Object,
    fromDate: {
      type: String,
      required: true,
    },
  },
  components: {
    CustomLoader,
  },
  created() {
    // this.dateTime = moment().format("YYYY-MM-DD HH:mm:ss");
    // if (this.fromDate) {
    //     this.dateTime = moment(new Date(this.fromDate)).format("YYYY-MM-DD HH:mm:ss");
    // }
  },
  computed: {
    getDynamicFormFields() {
      if (this.dynamicForm && this.dynamicForm.bookings) {
        return this.dynamicForm.bookings;
      }
      return this.dynamicForm;
    },
  },
  methods: {
    onCheckChange(e) {
      if (e.target.checked) {
        e.target.nextSibling.value = "on";
      } else {
        e.target.nextSibling.value = "off";
      }
      console.log(e.target, e.target.nextSibling.value);
    },
    async eventCancel() {
      if (!this.showCancelReason) {
        this.showCancelReason = true;
        return;
      }
      let data = {
        cancelReason: this.cancelReason,
        bookingId: this.eventDetails.TaskID,
      };
      if (this.isBusy) return;
      try {
        this.isBusy = true;
        const response = await axios.post("CancelBooking", data);
        const severity = response.data.success ? "success" : "error";
        this.$toast.add({
          severity: severity,
          detail: response.data.message,
          group: "br",
          life: 10000,
        });
        if (response.data.success) {
          this.$emit("on-cancel");
          this.refreshData();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    async handleEmailResend() {
      try {
        this.isBusy = true;
        const res = await axios.post(`ResendEmailBooking`, null, {
          params: {
            bookingId: this.eventDetails.TaskID,
          },
        });
        if (res.data.success) {
          this.$emit("on-reschedule");
        }
        //show toast
        this.$toast.add({
          severity: res.data.success ? "success" : "error",
          detail: res.data.message,
          group: "br",
          life: 10000,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }
    },
    async handleReschedule() {
      try {
        this.isBusy = true;
        const res = await axios.post(`RescheduleBooking`, {
          bookingId: this.eventDetails.TaskID,
          newDate: moment(this.dateTime).format("YYYY-MM-DD HH:mm:ss"),
        });
        if (res.data.success) {
          this.$emit("on-reschedule");
        }
        //show toast
        this.$toast.add({
          severity: res.data.success ? "success" : "error",
          detail: res.data.message,
          group: "br",
          life: 10000,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }
    },
  },
  data() {
    return {
      dateTime: moment(
        this.fromDate == null ? new Date() : this.fromDate
      ).format("YYYY-MM-DD HH:mm"),
      isBusy: false,
      showCancelReason: false,
    };
  },
};
</script>
