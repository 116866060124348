<template>
  <div class="header">
    <Toast position="bottom-right" group="br" />
  </div>
  <!-- make fixed position div -->
  <div class="busy-loader" v-if="isBusy">
    <ProgressBar mode="indeterminate" style="height: 5px"></ProgressBar>
  </div>
  <div class="contentBody">
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['isBusy']),
  },
}
</script>