import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import './assets/interceptors/axios'
import 'primevue/resources/themes/lara-light-green/theme.css'
import 'primeicons/primeicons.css'
import '/node_modules/primeflex/primeflex.css'
import './assets/style.css'


import { registerLicense } from '@syncfusion/ej2-base';



import Button from "primevue/button"
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';

const app = createApp(App);
app.use(PrimeVue)
app.component('Button', Button);
app.component('Card', Card);
app.component('InputText', InputText);
app.component('Dialog', Dialog);
app.component('Textarea', Textarea);
app.component('Calendar', Calendar);
app.component('Dropdown', Dropdown);
app.component('InputNumber', InputNumber);
app.component('Checkbox', Checkbox);
app.component('MultiSelect', MultiSelect);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);







import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import { setupAxiosLoaderInterceptor } from './helpers/busyIndicatorHelper'


let user = JSON.parse(localStorage.getItem('user'))
if (user != null) {
    app.config.globalProperties.userName = user.Username
}


registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH1ccXRWRmheUkF0XkQ=');

app.use(store);
app.use(ToastService);
app.component('Toast', Toast);

app.use(router);

setupAxiosLoaderInterceptor(store);
app.mount('#app');
