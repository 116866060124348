<template>
    <div class="login">
        <div class="login-wrapper">
            <div class="logoHeader text-center">
                <img src="../assets/logo.png">
                <p class="logoText">Yuguo</p>
            </div>
            <div class="loginForm">
                <Card style="width: 25em">
                    <template #content>
                        <form @submit.prevent="handleLogin">
                            <label for="username" class="block text-900 font-medium mb-2">Username</label>
                            <InputText class="w-full" id="username" v-model="loginForm.username"
                                :class="[v$.loginForm.username.$error ? 'p-invalid' : '']" />
                            <div class="p-error" v-if="v$.loginForm.username.$error"><small>Username is Required</small>
                            </div>

                            <label for="password" class="block text-900 font-medium mb-2 mt-3">Password</label>
                            <InputText type="password" class="w-full" id="password" v-model="loginForm.password"
                                :class="[v$.loginForm.password.$error ? 'p-invalid' : '']" />
                            <div class="p-error" v-if="v$.loginForm.password.$error"><small>Password is Required</small>
                            </div>


                            <Button type="submit" class="w-full justify-content-center mt-3" label="Submit"
                                :icon="loading ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'" />

                        </form>

                    </template>
                </Card>
            </div>
        </div>

    </div>
</template>
  
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {

    name: 'LoginView',
    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            loginForm: {
                username: "",
                password: ""
            },
            loading: false
        }
    },
    validations() {
        return {
            loginForm: {
                username: { required },
                password: { required }
            }
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/calendar");
        }
    },
    methods: {
        async handleLogin() {
            this.loading = true
            const result = await this.v$.$validate();
            if (result) {
                this.$store.dispatch('auth/login', this.loginForm).then(
                    (data) => {

                        if (data.success) {
                            this.$toast.add({ severity: 'success', detail: data.message, group: 'br', life: 10000 });
                            let user = JSON.parse(localStorage.getItem('user'));
                            let token = null;
                            if (user != null) {
                                token = user.token
                            }
                            if (token) {
                                window.location.href = "/calendar";
                            }
                            this.loading = false
                        } else {
                            this.loading = false
                            this.$toast.add({ severity: 'error', detail: data.message, group: 'br', life: 10000 });
                        }
                    },
                    (error) => {
                        this.loading = false
                        this.$toast.add({ severity: 'error', detail: error.message, group: 'br', life: 10000 });

                    }
                );
            } else {
                this.loading = false

            }

        }
    }

}
</script>
  