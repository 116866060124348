<template>
  <NavBar></NavBar>
  <div class="custom-grid-tablet">
    <div>
      <Button class="w-full mb-3" label="Create" icon="pi pi-plus" outlined @click="showBookingMgmt" />
      <ejs-calendar class="w-full mx-auto" id="calendar" :change="onValueChange"></ejs-calendar>
      <div class="mt-4">
        <label class="block mb-3 font-bold">Categories</label>

        <div v-for="category of categories" :key="category.id" class="flex align-items-center mb-2">
          <Checkbox v-model="filterCategories" @change="onChange" :inputId="category.code" name="category"
            :data-category="category.id" :value="category.id" />
          <label class="ml-2 text-sm" :for="category.code">{{
            category.title
          }}</label>
        </div>
        <label class="block mb-3 font-bold">Break</label>
        <Dropdown v-model="breakFilter.selectedOption" :options="breakFilter.options" optionLabel="label"
          @change="onBreakFilterChange" optionValue="value" placeholder="Select Break" class="w-full" />
        <div class="mt-3 border-solid border-primary-500 border-round" v-show="patientCount">
          <p class="text-center uppercase">Count</p>
          <hr />
          <div class="text-center">
            <p>
              <strong>{{ patientCount?.morning }}</strong>
            </p>
            <hr />
            <p>
              <strong>{{ patientCount?.afternoon }}</strong>
            </p>
            <hr />
            <p>
              <strong>{{ patientCount?.night }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ejs-schedule :selectedDate="selectedDate" v-model="selectedDate" id="Schedule" ref="ScheduleObj" width="100%"
        :event-rendered="onEventRendered" :navigating="onNavigating" height="calc(100vh - 80px)" currentView="Agenda"
        :eventSettings="eventSettings()" :popupOpen="onPopupOpen">
        <e-views>
          <e-view option="Day"></e-view>
          <e-view option="Week"></e-view>
          <e-view option="Month"></e-view>
          <e-view option="Agenda"></e-view>
        </e-views>
      </ejs-schedule>
    </div>
  </div>
  <div class="card flex justify-content-center">
    <Dialog v-model:visible="visible" modal :header="getComputedTitle" :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div v-if="isBusy">
        <ProgressSpinner class="w-full" />
      </div>
      <div v-if="!isBusy">
        <div class="flex flex-wrap align-items-center justify-content-center gap-3" v-if="!editEvent">
          <Button outlined @click="bookingType = 'new'" label="New Booking" icon="pi pi-plus-circle"
            :severity="bookingType == 'new' ? 'primary' : 'secondary'" size="large" />
          <Button @click="bookingType = 'over'" label="Override Slot" icon="pi pi-clock" size="large" outlined
            :severity="bookingType == 'over' ? 'primary' : 'secondary'" />
        </div>
        <div class="grid mt-3" v-if="bookingType && !editEvent">
          <div class="col-12">
            <label class="block mb-2 font-bold">Category</label>
            <Dropdown v-model="selectedcategory" :options="categories" optionLabel="title"
              placeholder="Select a Category" class="w-full" @change="categoryChange" />
          </div>
        </div>
        <DynamicForm :dynamicForm="dynamicForm" :submit-action="editEvent ? eventEdit : submitEvent"
          @on-cancel="refreshData" :eventDetails="eventDetails" v-if="bookingType == 'new'" :from-date="fromDate"
          @on-reschedule="refreshData" />
        <OverrideForm :event="eventDetails" v-if="bookingType == 'over'" @on-override="refreshData"
          :cell-date="cellDate" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import NavBar from "@/components/navbar.vue";
import axios from "axios";
import moment from "moment";
import DynamicForm from "@/components/DynamicForm.vue";
import OverrideForm from "@/components/OverrideForm.vue";
import {
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Day,
  Week,
  Agenda,
  Month,
} from "@syncfusion/ej2-vue-schedule";
import { CalendarComponent } from "@syncfusion/ej2-vue-calendars";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { hubConnection } from "signalr-no-jquery";
import { getBaseAddressWithoutVersion } from "@/helpers/baseAddress";
export default {
  name: "AgendaCalendar",
  components: {
    NavBar,
    "ejs-calendar": CalendarComponent,
    "ejs-schedule": ScheduleComponent,
    "e-view": ViewDirective,
    "e-views": ViewsDirective,
    DynamicForm,
    OverrideForm,
  },
  provide: {
    schedule: [Day, Week, Month, Agenda],
  },
  data() {
    return {
      patientCount: null,
      categoryCheckTimeout: null,
      bookingCalendarData: [],
      visible: false,
      eventForm: {
        slotCapacity: 0,
        startDate: new Date(),
        endDate: new Date(),
      },
      dateTime: new Date(),
      // timezone: "Asia/Singapore",
      isBusy: false,
      navigatedDate: new Date(),
      selectedDate: new Date(),
      fromDate: null,
      filterCategories: localStorage.getItem("filterCategories")
        ? JSON.parse(localStorage.getItem("filterCategories"))
        : [],
      cellDate: null,
      categories: null,
      bookingType: null,
      selectedcategory: null,
      isFirstRun: true,
      dynamicForm: null,
      eventDetails: null,
      modalHeader: "Create Booking",
      cancelReason: null,
      cancelEvent: false,
      editEvent: false,
      updateEventDate: null,
      editButtonText: "Edit",
      breakFilter: {
        selectedOption: localStorage.getItem("breakFilter")
          ? parseInt(localStorage.getItem("breakFilter"), 10)
          : 2,
        options: [
          { label: "Hide Break", value: 1 },
          { label: "Hide Override", value: 2 },
          { label: "Show Both", value: 3 },
        ],
      },
    };
  },
  created() {
    this.setupSignalR();
    this.getBookings(this.selectedDate);
    this.getCategories().then((categories) => {
      categories.forEach((c) => {
        const categoryChkbx = document.querySelector(
          `[data-category="${c.id}"]`
        );
        if (categoryChkbx && categoryChkbx.children[1]) {
          categoryChkbx.children[1].style.backgroundColor = c.color;
        }
      });
    });
  },
  mounted() {
    // this.hideSynfusionLicense();
  },
  computed: {
    getComputedTitle() {
      if (!this.bookingType) {
        return "Manage Bookings and Slots";
      }
      const bookingTitle = this.editEvent ? "Edit Booking" : "Create Booking";
      const overrideTitle = this.editEvent ? "Edit Override" : "Override Slot";
      return this.bookingType == "new" ? bookingTitle : overrideTitle;
    },
  },
  methods: {
    setupSignalR() {
      const connection = hubConnection(process.env.VUE_APP_BASE_ADDRESS);
      const hubProxy = connection.createHubProxy("BookingHub");
      hubProxy.on("notifyBooking", (message) => {
        console.log("## notify booking");
        this.refreshData(true);
      });
      hubProxy.on("notifyBookingCategory", (message) => {
        console.log("## notify category update");
        this.getCategories(true);
      });
      connection
        .start({ jsonp: true })
        .done(function () {
          console.log("Now connected, connection ID=" + connection.id);
        })
        .fail(function () {
          console.log("Could not connect");
        });
    },
    showBookingMgmt() {
      this.bookingType = null;
      this.bookingType == "new";
      this.editEvent = false;
      this.visible = true;
      this.selectedcategory = null;
      this.dynamicForm = null;
      this.eventDetails = {};
      this.fromDate = new Date();
    },
    onEventRendered: function (args) {
      if (args.data.Color) {
        args.element.style.backgroundColor = args.data.Color;
      }
    },
    onNavigating: async function (args) {
      if (this.isBusy) return;
      try {
        this.isBusy = true;
        await this.getBookings(args.currentDate);
        this.navigatedDate = args.currentDate;
        this.getActiveCategoryPatientCountInfo();
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    onPopupOpen: function (args) {
      args.cancel = true;
      this.cellDate = moment(args.data.StartTime).format("YYYY-MM-DD");
      this.bookingType = null;
      this.selectedcategory = null;
      this.dynamicForm = null;
      this.eventDetails = null;
      this.cancelReason = null;
      this.cancelEvent = false;
      this.editEvent = false;
      this.updateEventDate = null;
      this.editButtonText = "Edit";
      this.eventDetails = args.data;
      // if (
      //   args.type === "QuickInfo" ||
      //   args.type === "Editor" ||
      //   args.type == "EventContainer"
      // ) {
      //   args.cancel = true;
      // }
      if (args.data.Id > 0) {
        this.editEvent = true;
        this.getDynamicFormFieldWithData(args.data);
        this.visible = true;
        this.bookingType = args.data.OverrideType == 0 ? "new" : "over";
      } else {
        this.editEvent = false;
        this.visible = true;
      }
    },
    //removed the eventSettings object from data() and added it as a method instead
    //this is used on the ejs-schedule component
    eventSettings() {
      return {
        dataSource: [...this.bookingCalendarData],
      };
    },
    getActiveCategoryPatientCountInfo: function () {
      let categories = Array.from(
        document.querySelectorAll('input[name="category"]:checked')
      ).map((checkbox) => checkbox.value);
      if (!categories.length) {
        categories = [0];
      }
      this.getPatientBookingCount(categories);
    },
    onChange: function () {
      clearTimeout(this.categoryCheckTimeout);
      this.categoryCheckTimeout = setTimeout(() => {
        this.getActiveCategoryPatientCountInfo();
      }, 1000);
      let scheduleObj = this.$refs.ScheduleObj;
      let predicate;
      if (this.filterCategories.length > 0) {
        localStorage.setItem(
          "filterCategories",
          JSON.stringify(this.filterCategories)
        );
      } else {
        localStorage.removeItem("filterCategories");
      }
      this.filterCategories.forEach((checkBoxObj) => {
        if (predicate) {
          predicate = predicate.or(
            "CategoryId",
            "equal",
            parseInt(checkBoxObj, 10)
          );
        } else {
          predicate = new Predicate(
            "CategoryId",
            "equal",
            parseInt(checkBoxObj, 10)
          );
        }
      });
      predicate = predicate?.or("CategoryId", "equal", null);
      //include break in predicate
      if (this.breakFilter.selectedOption == 1) {
        //hide break
        predicate = predicate?.and("OverrideType", "notequal", 2) ?? new Predicate("OverrideType", "notequal", 2);
      } else if (this.breakFilter.selectedOption == 2) {
        //hide override
        predicate = predicate?.and("OverrideType", "notequal", 1) ?? new Predicate("OverrideType", "notequal", 1);
      }
      scheduleObj.ej2Instances.eventSettings.query = new Query().where(
        predicate
      );
    },
    runDefaultQuery() {
      //   let scheduleObj = this.$refs.ScheduleObj;
      //   const predicate = new Predicate("OverrideType", "notequal", 2);
      //   scheduleObj.ej2Instances.eventSettings.query = new Query().where(
      //     predicate
      //   );
      this.onChange();
      this.onBreakFilterChange();
    },
    onBreakFilterChange() {
      console.log("change filter");
      let scheduleObj = this.$refs.ScheduleObj;
      let predicate;
      this.filterCategories.forEach((checkBoxObj) => {
        if (predicate) {
          predicate = predicate.or(
            "CategoryId",
            "equal",
            parseInt(checkBoxObj, 10)
          );
        } else {
          predicate = new Predicate(
            "CategoryId",
            "equal",
            parseInt(checkBoxObj, 10)
          );
        }
      });
      localStorage.setItem("breakFilter", this.breakFilter.selectedOption);
      predicate = predicate?.or("CategoryId", "equal", null);
      if (this.breakFilter.selectedOption == 1) {
        //hide break
        predicate = predicate?.and("OverrideType", "notequal", 2) ?? new Predicate("OverrideType", "notequal", 2);
      } else if (this.breakFilter.selectedOption == 2) {
        //hide override
        predicate = predicate?.and("OverrideType", "notequal", 1) ?? new Predicate("OverrideType", "notequal", 1);
      }
      scheduleObj.ej2Instances.eventSettings.query = new Query().where(
        predicate
      );
    },
    refreshData(triggeredBySignalR = false) {
      if(this.visible && !triggeredBySignalR){
        this.visible = false;
      }
      this.getBookings(this.navigatedDate);
      this.getActiveCategoryPatientCountInfo();
    },
    async getPatientBookingCount(categories) {
      try {
        const response = await axios.get("GetPatientBookingCount", {
          params: {
            categoryIds: categories,
            date: moment(this.navigatedDate).format("MM/DD/YYYY"),
          },
          paramsSerializer: {
            indexes: true,
          },
        });
        this.patientCount = response.data.result;
      } catch (error) {
        console.error(error);
      }
    },
    async getDynamicFormFieldWithData(data) {
      try {
        this.isBusy = true;
        const response = await axios.get("GetCategoryFieldsValue", {
          params: {
            categoryId: data.CategoryId,
            bookingId: data.TaskID,
          },
        });
        this.fromDate = response.data.result?.bookingDate;
        this.dynamicForm = response.data.result;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    async addEvent() {
      if (this.bookingType == "over") {
        if (this.selectedcategory == null) {
          this.$toast.add({
            severity: "error",
            detail: "Please select a category",
            group: "br",
            life: 10000,
          });
          return;
        }
        let obj = {
          categoryId: this.selectedcategory.id,
          timeFrom: this.eventForm.startDate,
          timeTo: this.eventForm.endDate,
          effectiveOnly: true,
          slotCapacity: this.eventForm.slotCapacity,
        };
        if (this.isBusy) return;
        try {
          this.isBusy = true;
          const response = await axios.post("BookingOverride", obj);
          const severity = response.data.success ? "success" : "error";
          this.$toast.add({
            severity: severity,
            detail: response.data.message,
            group: "br",
            life: 10000,
          });
          if (response.data.success) {
            this.refreshData();
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.isBusy = false;
        }
      } else {
        this.$refs.formSubmit.click();
      }
    },
    async getBookings(date) {
      let currentDate = date || new Date();
      const fd = moment(currentDate)
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD/YYYY");

      const ld = moment(currentDate)
        .endOf("month")
        .add(7, "days")
        .format("MM/DD/YYYY");
      try {
        const response = await axios.get("GetBookingCalendar", {
          params: {
            // categoryId: 1,
            start: fd,
            end: ld,
          },
        });
        if (response.status == 200 && response.data.success) {
          this.bookingCalendarData = response.data.result;
          if (this.isFirstRun) {
            this.runDefaultQuery();
            this.isFirstRun = false;
          }
        } else {
          this.$toast.add({
            severity: "error",
            detail: response.data.message,
            group: "br",
            life: 10000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCategories() {
      try {
        this.isBusy = true;
        const response = await axios.get("GetBookingCategory?isActive=true");
        if (response.status == 200 && response.data.success) {
          this.categories = response.data.result;
        }
        return response.data.result;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    async categoryChange() {
      this.eventDetails.CategoryId = this.selectedcategory.id;
      if (this.bookingType == "new" && this.selectedcategory) {
        try {
          this.isBusy = true;
          const response = await axios.get(
            "GetCategoryFields?categoryId=" + this.selectedcategory.id
          );
          this.fromDate = this.cellDate;
          this.dynamicForm = response.data.result;
        } catch (error) {
          console.error(error);
        } finally {
          this.isBusy = false;
        }
      }
    },
    async submitEvent(e) {
      var formData = new FormData(e.target);
      if (this.selectedcategory == null) {
        this.$toast.add({
          severity: "error",
          detail: "Please select a category",
          group: "br",
          life: 10000,
        });
        return;
      }
      if (this.isBusy) return;
      try {
        this.isBusy = true;
        const response = await axios.post("AddBooking", formData, {
          params: {
            CategoryId: this.selectedcategory.id,
          },
        });
        const severity = response.data.success ? "success" : "error";
        this.$toast.add({
          severity: severity,
          detail: response.data.message,
          group: "br",
          life: 10000,
        });
        if (response.data.success) {
          this.refreshData();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    formatDateTime(value) {
      return moment(value).format("MMMM Do YYYY, h:mm:ss a");
    },
    async eventEdit(e) {
      if (this.isBusy) return;
      try {
        var formData = new FormData(e.target);
        this.isBusy = true;
        const response = await axios.post("EditBooking", formData, {
          params: {
            Id: this.eventDetails.TaskID,
            CategoryId: this.eventDetails.CategoryId,
          },
        });
        const severity = response.data.success ? "success" : "error";
        this.$toast.add({
          severity: severity,
          detail: response.data.message,
          group: "br",
          life: 10000,
        });
        if (response.data.success) {
          this.refreshData();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    onValueChange: function (args) {
      this.selectedDate = args.value;
    },
    hideSynfusionLicense() {
      const divElements = document.getElementsByTagName("div");
      for (const div of divElements) {
        if (div.textContent.includes("This application")) {
          div.style.display = "none";
          break;
        }
      }
    },
  },
};
</script>
