<template>
    <ProgressSpinner v-if="loading" />
</template>
<script>
export default {
    name: 'CustomLoader',
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>