import axios from "axios";
export const setupAxiosLoaderInterceptor = (busyStore) => {
    axios.interceptors.request.use(
        (config) => {
            if (config && config.url)
                busyStore.commit('setBusyCaller', {
                    key: config.url,
                    isBusy: true,
                });
            return config;
        },
        (error) => {
            if (error && error.config && error.config.url)
                busyStore.commit('setBusyCaller', {
                    key: error.config.url,
                    isBusy: false,
                });
            return error;
        }
    );

    axios.interceptors.response.use(
        (config) => {
            try {
                busyStore.commit('setBusyCaller', {
                    key: config.config.url,
                    isBusy: false,
                });
            } catch (e) {
                console.error(e);
            }

            return config;
        },
        (error) => {
            if (error && error.config && error.config.url)
                busyStore.commit('setBusyCaller', {
                    key: error.config.url,
                    isBusy: false,
                });
            return error;
        }
    );
};