import axios from "axios";
import { getBaseAddress } from "@/helpers/baseAddress";
axios.defaults.baseURL = getBaseAddress();
let user = JSON.parse(localStorage.getItem('user'))
let token = null;
if (user != null) {
    token = user.token
}
axios.defaults.headers.common['Authorization'] = 'Bearer  ' + token;


axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error)
    if (error.response.status == 401 || error.response.status == 405) {
        window.location.href = '/';
        localStorage.removeItem('user');
    }
    return Promise.reject(error);
});