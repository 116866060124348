<template>
    <form @submit.prevent="onSubmit">
        <CustomLoader :loading="isBusy" />
        <div class="grid" v-show="!isBusy">
            <!-- show radio buttons here for override type -->
            <h4 v-if="isEditMode" class="col-12">Category: {{ event.Category }}</h4>
            <div v-for="option in overrideTypes" :key="option.value" class="col-12">
                <div>
                    <input type="radio" v-model="overrideType" :value="option.value" :label="option.label"
                        :id="option.label" />
                    <label :for="option.label">{{ option.label }}</label>
                </div>
            </div>
            <!-- start - end date -->
            <div class="col-12">
                <label for="startDate" class="block">Start Date</label>
                <input type="date" class="form-control p-component p-inputtext w-full" id="startDate" v-model="startDate"
                    :readonly="fixedIsRecurring && isRecurring" required>
            </div>
            <!-- time from - time to -->
            <div class="col-6">
                <label for="timeFrom" class="block">Time From</label>
                <input type="time" class="form-control p-component p-inputtext w-full" id="timeFrom" v-model="timeFrom"
                    required>
            </div>
            <div class="col-6">
                <label for="timeTo" class="block">Time To</label>
                <input type="time" class="form-control p-component p-inputtext w-full" id="timeTo" v-model="timeTo"
                    required>
            </div>
            <!-- override type -->
            <!-- <div class="col-12">
                <label for="overrideType" class="block">Override Type</label>
                <select class="form-control p-component p-inputtext w-full" id="overrideType" v-model="overrideType"
                    required>
                    <option v-for="(type, index) in overrideTypes" :key="index" :value="type.value">
                        {{ type.label }}
                    </option>
                </select>
            </div> -->
            <!-- slot capacity -->
            <div class="col-12" v-if="overrideType === 1">
                <label for="slotCapacity" class="block">Slot Capacity</label>
                <input type="number" class="form-control p-component p-inputtext w-full" id="slotCapacity" min="0"
                    v-model="slotCapacity" required>
            </div>
            <!-- is recurring -->
            <div class="col-12">
                <input type="checkbox" class="form-control" id="isRecurring" v-model="isRecurring">
                <label for="isRecurring">Recurring</label>
            </div>
            <!-- end date -->
            <div class="col-6" v-if="isRecurring">
                <label for="endDate" class="block">End Date</label>
                <input type="date" class="form-control p-component p-inputtext w-full" id="endDate" v-model="endDate"
                    :readonly="fixedIsRecurring === isRecurring" required>
            </div>
            <!-- repeat type -->
            <div class="col-6" v-if="isRecurring">
                <label for="repeatType" class="block">Repeat Type</label>
                <select class="form-control p-component p-inputtext w-full" id="repeatType" v-model="repeatType" required
                    @change="onRepeatTypeChange">
                    <option v-for="(type, index) in repeatTypes" :key="index" :value="type.value">
                        {{ type.label }}
                    </option>
                </select>
            </div>
            <!-- repeat day of month -->
            <div class="col-6" v-if="isRecurring && showRepeatDayOfMonth">
                <label for="repeatOn" class="block">Repeat On</label>
                <MultiSelect v-model="repeatOn" :options="dates" optionLabel="label" optionValue="value" class="w-full" />
            </div>
            <!-- repeat on -->
            <div class="col-6" v-if="isRecurring && showRepeatWeek">
                <label for="repeatOn" class="block">Repeat On</label>
                <MultiSelect v-model="repeatOn" :options="days" optionLabel="label" optionValue="value" class="w-full" />
            </div>
            <!-- repeat frequency -->
            <div class="col-6" v-if="isRecurring">
                <label for="repeatFrequency" class="block">Repeat Frequency</label>
                <input type="number" class="form-control p-component p-inputtext w-full" id="repeatFrequency" required
                    min="0" v-model="repeatFrequency">
            </div>
            <!-- submit -->
            <div class="col-12 text-right">
                <Button label="Override" type="submit" icon="pi pi-check" v-if="!isEditMode" />
                <Button :label="isEditMode ? 'Edit Override' : 'Override'" icon="pi pi-check" @click="handleEditOverride"
                    v-if="isEditMode" />
                <Button :label="isEditMode ? 'Delete' : null" icon="pi pi-check" severity="danger"
                    @click="actionMode = 'delete'" class="ml-3" v-if="isEditMode" />
            </div>
            <OverrideActionOption v-model:visible="actionMode" :on-delete="deleteOverride" :on-edit="editOverride"
                :is-recurring="fixedIsRecurring" :action-mode="actionMode" :recurrance-rule="getRecurranceModificationRule"
                :modal-header="getComputedOverrideActionTitleHeader" />
        </div>
    </form>
</template>
<script>
import axios from 'axios';
import OverrideActionOption from '@/components/OverrideActionOption.vue';
import CustomLoader from '@/components/CustomLoader.vue';
import moment from 'moment';
export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        selectedCategory: {
            type: Object,
            required: false,
        },
        cellDate: {
            type: String,
            required: false,
        }
    },
    components: {
        OverrideActionOption,
        CustomLoader,
    },
    created() {
        this.getOverrideInfo();
        this.startDate = moment(this.event.StartTime).format('YYYY-MM-DD');
    },
    watch: {
        isRecurring(val) {
            if (this.isEditMode) {
                if (this.fixedIsRecurring === val) {
                    this.startDate = this.oldResult.startDate;
                }
                if (!val) { //user trying to make it non-recurring, so null the recurring settings
                    this.endDate = this.cellDate;
                    this.repeatType = null;
                    this.repeatFrequency = null;
                }
                else { //user reverted the changes, so set the old values
                    this.endDate = this.oldResult.endDate;
                    this.repeatType = this.oldResult.repeatType;
                    this.repeatFrequency = this.oldResult.repeatFrequency;
                }
                return;
            }
            if (val) { //recurring
                this.endDate = null;
            }
            else { //non recurring
                this.endDate = this.cellDate;
                this.repeatType = null;
                this.repeatFrequency = null;
            }
        }
    },
    methods: {
        handleEditOverride() {
            if (this.overrideType === 1 && this.slotCapacity < 0) {
                this.$toast.add({ severity: "error", detail: "Please enter valid slot capacity", group: 'br', life: 10000 });
                return;
            }
            if (this.isRecurring) {
                if (!this.repeatType) {
                    this.$toast.add({ severity: "error", detail: "Please select repeat type", group: 'br', life: 10000 });
                    return;
                }
                if (!this.repeatFrequency || this.repeatFrequency < 0) {
                    this.$toast.add({ severity: "error", detail: "Please enter valid repeat frequency", group: 'br', life: 10000 });
                    return;
                }
                if ((this.repeatType === 2 || this.repeatType === 3) && (this.repeatOn == null || this.repeatOn?.length == 0)) {
                    this.$toast.add({ severity: "error", detail: "Please select repeat on", group: 'br', life: 10000 });
                    return;
                }
            }
            this.actionMode = 'edit';
        },
        async deleteOverride(selectedOptionType) {
            if (this.isBusy) return;
            try {
                this.isBusy = true;
                this.actionMode = null;
                const res = await axios.get(`DeleteBookingOverride`, {
                    params: {
                        id: this.event.TaskID,
                        selectedDate: moment(this.event.StartTime).format('YYYY-MM-DD'),
                        eventActionType: selectedOptionType,
                    }
                });
                if (res.data.success) {
                    this.$emit('on-override');
                }
                this.$toast.add({ severity: res.data.success ? "success" : "error", detail: res.data.message, group: 'br', life: 10000 });
            }
            catch (err) {
                console.error(err);
            }
            finally {
                this.isBusy = false;
            }
        },
        async editOverride(selectedOptionType) {
            if (this.isBusy) return;
            try {
                this.actionMode = null;
                this.isBusy = true;
                const res = await axios.post(`EditBookingOverride`, this.getComputedPostData, {
                    params: {
                        id: this.event.TaskID,
                        selectedDate: this.cellDate,
                        eventActionType: selectedOptionType,
                    }
                });
                if (res.data.success) {
                    this.$emit('on-override');
                }
                this.$toast.add({ severity: res.data.success ? "success" : "error", detail: res.data.message, group: 'br', life: 10000 });
            }
            catch (err) {
                console.error(err);
            }
            finally {
                this.isBusy = false;
            }
        },
        async getOverrideInfo() {
            try {
                const res = await axios.get(`GetBookingOverride`, {
                    params: {
                        taskId: this.event.TaskID,
                        dateFrom: moment(this.event.StartTime).format('YYYY-MM-DD'),
                        dateTo: moment(this.event.EndTime).format('YYYY-MM-DD'),
                    }
                });
                const result = res.data.result;
                if (!result) {
                    return;
                }
                this.oldResult = result;
                this.oldResult.startDate = moment(this.oldResult.startDate).format('YYYY-MM-DD')
                this.oldResult.endDate = moment(this.oldResult.endDate).format('YYYY-MM-DD')
                this.isEditMode = true;
                this.startDate = moment(result.startDate).format('YYYY-MM-DD');
                this.endDate = moment(result.endDate).format('YYYY-MM-DD');
                this.timeFrom = result.timeFrom;
                this.categoryId = result.categoryId;
                this.timeTo = result.timeTo;
                this.overrideType = result.overrideType;
                this.slotCapacity = result.slotCapacity;
                this.isRecurring = result.isRecurring;
                this.fixedIsRecurring = result.isRecurring;
                this.repeatType = result.repeatType;
                this.onRepeatTypeChange();
                this.repeatFrequency = result.repeatFrequency;
                this.repeatOn = result.repeatOn?.split(',').map(day => parseInt(day));
            }
            catch (err) {
                console.error(err);
            }
        },
        async onSubmit() {
            const postData = this.getComputedPostData;
            if (!postData.categoryId) {
                this.$toast.add({ severity: "error", detail: "Please select a category", group: 'br', life: 10000 });
                return;
            }
            if (this.isBusy) return;
            try {
                this.isBusy = true;
                const res = await axios.post('BookingOverride', postData);
                if (res.data.success) {
                    this.$emit('on-override');
                }
                this.$toast.add({ severity: res.data.success ? "success" : "error", detail: res.data.message, group: 'br', life: 10000 });
            }
            catch (err) {
                console.error(err);
            }
            finally {
                this.isBusy = false;
            }
        },
        onRepeatTypeChange() {
            this.showRepeatWeek = false;
            this.showRepeatDayOfMonth = false;

            if (this.repeatType == 2) { //week
                this.showRepeatWeek = true;
            } else if (this.repeatType == 3) { //month
                this.showRepeatDayOfMonth = true;
            }
        },
    },
    computed: {
        getComputedOverrideActionTitleHeader() {
            return this.actionMode === "edit" ? "Edit Override" : "Delete Override";
        },
        getRecurranceModificationRule() {
            if (!this.oldResult) {
                return false;
            }
            return this.isRecurring != this.oldResult.isRecurring ||
                this.startDate != this.oldResult.startDate ||
                this.endDate != this.oldResult.endDate ||
                this.repeatType != this.oldResult.repeatType ||
                this.repeatFrequency != this.oldResult.repeatFrequency ||
                this.repeatOn != this.oldResult.repeatOn;
        },
        getComputedPostData() {
            return {
                categoryId: this.isEditMode ? this.categoryId : this.event.CategoryId,
                startDate: this.startDate,
                endDate: this.endDate,
                timeFrom: this.timeFrom,
                timeTo: this.timeTo,
                overrideType: this.overrideType,
                slotCapacity: this.overrideType == 2 ? null : this.slotCapacity, //if break, slot capacity is null
                isRecurring: this.isRecurring,
                repeatType: this.repeatType,
                repeatFrequency: this.repeatFrequency,
                repeatOn: this.repeatType === 1 || this.repeatType === 4 ? null : this.repeatOn?.map(day => day).join(','), //comma separated values
            }
        }
    },
    data() {
        return {
            isEditMode: false,
            actionMode: null,
            oldResult: null,
            startDate: null,
            endDate: new Date(),
            isBusy: false,
            timeFrom: new Date(),
            timeTo: new Date(),
            overrideType: 1,
            categoryId: null,
            showRepeatWeek: false,
            showRepeatDayOfMonth: false,
            repeatType: null,
            slotCapacity: null,
            isRecurring: false,
            fixedIsRecurring: false,
            repeatOn: null,
            dates: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })),
            days: [
                { label: "Sunday", value: 1 },
                { label: "Monday", value: 2 },
                { label: "Tuesday", value: 3 },
                { label: "Wednesday", value: 4 },
                { label: "Thursday", value: 5 },
                { label: "Friday", value: 6 },
                { label: "Saturday", value: 7 },
            ],
            repeatFrequency: 0,
            repeatTypes: [
                { label: "Daily", value: 1 },
                { label: "Weekly", value: 2 },
                { label: "Monthly", value: 3 },
                { label: "Yearly", value: 4 }
            ],
            overrideTypes: [
                { label: "Override", value: 1 },
                { label: "Break", value: 2 },
            ]
        }
    }
}
</script>