import { createStore } from 'vuex'
import { auth } from "./auth.module";


export default createStore({
  state: {
    busyCallers: [],
  },
  getters: {
    isBusy: (state) => state.busyCallers.length > 0,
  },
  mutations: {
    setBusyCaller(state, payload) {
      if (payload.isBusy && state.busyCallers.indexOf(payload.key) === -1) {
        state.busyCallers.push(payload.key);
      } else {
        state.busyCallers = state.busyCallers.filter((k) => k !== payload.key);
      }
    },
  },
  actions: {
  },
  modules: {
    auth
  },
})
