import axios from 'axios';

class AuthService {
  login(user) {
    console.log(user)
    return axios.post('login', {
      Username: user.username,
      Password: user.password
    })
      .then(response => {
        console.log(response)
        if (response.status == 200) {
          if (response.data.success && response.data.result.token) {
            localStorage.setItem('user', JSON.stringify(response.data.result));
          }
          return response.data;
        }
      });
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();
